<div class="layout-container">
  <mat-toolbar fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="logo">
        <img [src]="'assets/images/logo.png'">
      </div>
      <mat-nav-list *ngIf="isAuthenticated" fxShow="true" fxHide.lt-md="true">
        <div *ngIf="!isAdmin" fxLayout="row" fxLayoutAlign="flex-start center">
          <a mat-list-item disableRipple routerLinkActive="active" [routerLink]="['/inspections/ik']">
            <p>
              {{ 'IK' | translate }}
            </p>
          </a>
          <a mat-list-item disableRipple routerLinkActive="active" [routerLink]="['/inspections/ie']">
            <p>
              {{ 'IE' | translate }}
            </p>
          </a>
          <a mat-list-item disableRipple routerLinkActive="active" [routerLink]="['/inspections/iv']">
            <p>
              {{ 'IV' | translate }}
            </p>
          </a>
        </div>
        <div *ngIf="isAdmin" fxLayout="row" fxLayoutAlign="flex-start center">
          <a mat-list-item disableRipple routerLinkActive="active" [routerLink]="['users']">
            <p>
              {{ 'navbar.users' | translate }}
            </p>
          </a>
        </div>
      </mat-nav-list>
    </div>
    <div fxLayout="row" *ngIf="isAuthenticated">
      <mat-nav-list fxLayout="row" fxLayoutAlign="flex-start center" fxShow="true" fxHide.lt-md="true">
        <a *ngIf="!isAdmin" mat-list-item disableRipple routerLinkActive="active" [routerLink]="['history']">
          <p>
            {{ 'navbar.history' | translate }}
          </p>
        </a>
        <mat-form-field class="lang-container">
          <mat-select [ngModel]="selectedLanguage" (selectionChange)="onLanguageChange($event.value)">
            <mat-option *ngFor="let language of languageOptions" [value]="language.value">
              {{ language.display }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-nav-list>

      <div class="icon-container" fxLayout="column" fxLayoutAlign="center center" fxShow="true" fxHide.lt-md="true">
        <mat-icon [inline]="true" class="user-icon" #menu="matMenuTrigger" [matMenuTriggerFor]="userMenu" [ngClass]="{'user-icon-active' : menu.menuOpen}">more_vert</mat-icon>
      </div>
      <mat-menu #userMenu="matMenu" xPosition="before">
        <button *ngIf="!isAdmin" mat-menu-item (click)="openCSVExportDialog()">
          {{ 'navbar.csv-export' | translate }}
        </button>
        <button mat-menu-item (click)="openLogoutConfirmDialog()">
          {{ 'navbar.logout' | translate }}
        </button>
      </mat-menu>

      <mat-form-field fxShow="true" fxHide.gt-sm="true" class="lang-container">
        <mat-select [ngModel]="selectedLanguage" (selectionChange)="onLanguageChange($event.value)">
          <mat-option *ngFor="let language of languageOptions" [value]="language.value">
            {{ language.display }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button fxShow="true" fxHide.gt-sm="true" class="menu-btn" mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </mat-toolbar>

  <mat-sidenav-container class="example-container">
    <mat-sidenav #sidenav mode="over" opened="false" position="end" fxHide.gt-sm="true" (click)="sidenav.close()">
      <div *ngIf="isAuthenticated" fxLayout="column">
        <div *ngIf="!isAdmin" fxLayout="column">
          <a mat-button [routerLink]="['/inspections/ik']" routerLinkActive="active">
            {{ 'IK' | translate }}
          </a>
          <a mat-button [routerLink]="['/inspections/ie']" routerLinkActive="active">
            {{ 'IE' | translate }}
          </a>
          <a mat-button [routerLink]="['/inspections/iv']" routerLinkActive="active">
            {{ 'IV' | translate }}
          </a>
          <a mat-button [routerLink]="['/history']" routerLinkActive="active">
            {{ 'navbar.history' | translate }}
          </a>
          <button mat-button (click)="openCSVExportDialog()">
            {{ 'navbar.csv-export' | translate }}
          </button>
        </div>
        <a *ngIf="isAdmin" mat-button [routerLink]="['users']" routerLinkActive="active">
          {{ 'navbar.users' | translate }}
        </a>
        <button mat-button (click)="openLogoutConfirmDialog()">
          {{ 'navbar.logout' | translate }}
        </button>
      </div>
    </mat-sidenav>

    <mat-sidenav-content fxFlex fxLayout="column">
        <ng-content></ng-content>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
